<template functional>
  <a
    v-if="/^(?:[a-z]+:)?\/\//i.test(props.urlOrPath)"
    rel="noopener noreferrer"
    :href="props.urlOrPath"
    target="_blank"
    :class="[data.staticClass, data.class]"
    v-on="listeners"
  >
    <slot></slot>
  </a>
  <nuxt-link
    v-else-if="props.urlOrPath !== null"
    :to="props.urlOrPath"
    :class="[data.staticClass, data.class]"
    v-on="listeners"
  >
    <slot></slot>
  </nuxt-link>
</template>

<script>
export default {
  name: 'CmsLink',
  props: {
    urlOrPath: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>
